import { useState, useEffect } from "react";
import {
  ActivityProps,
  getDefaultValue,
  QuestionHeader,
  getFeedbackColor,
  getIconColor,
} from "../../../pages/components/GbfSlide";
import { twMerge } from "tailwind-merge";
import { Image, Icon } from "../../../atoms/components";
import { parseColorToStyle } from "../../../helpers/ColorHelper";

export function CheckBoxSlideActivity({
  examItem,
  savedResponses,
  handleResponse,
  finalAppearance,
}: ActivityProps) {
  const [selected, setSelected] = useState<string[]>(
    getDefaultValue({ examItem, savedResponses }) as string[]
  );

  const manageCheckboxSelections = (choiceUuid: string) => {
    if (selected.includes(choiceUuid)) {
      const updatedSelection = selected.filter(
        (choiceUuidInsideSelected) => choiceUuidInsideSelected !== choiceUuid
      );
      setSelected(updatedSelection);
    } else setSelected((prev) => [...prev, choiceUuid]);
  };

  const hasAnswered = savedResponses.some(
    (response) => response.item === examItem.uuid
  );

  useEffect(() => {
    handleResponse({
      item: examItem.uuid,
      choices: selected ? selected : [],
    });
  }, [selected]);

  const textStyle = finalAppearance?.finalTextStyle;

  return (
    <div>
      <QuestionHeader text={examItem.text || ""} style={textStyle} />

      {examItem.choices?.map((choice) => {
        return (
          <button
            key={choice?.uuid}
            onClick={() => {
              manageCheckboxSelections(choice?.uuid || "");
            }}
            className={twMerge(
              `w-full text-lg text-gray-700 mb-4 rounded-3xl p-4 border-black border-2 flex items-center  active:bg-gray-300 transition-all ${getFeedbackColor(
                {
                  choiceUuid: choice?.uuid || "",
                  hasAnswered: hasAnswered,
                  isCorrect: choice?.isCorrect || false,
                  selected: (selected as string[]) || "",
                  showResults: examItem.showResults || false,
                }
              )}  ${
                selected.includes(choice?.uuid || "")
                  ? ""
                  : hasAnswered
                  ? "hover:bg-white"
                  : "hover:bg-gray-300 active:bg-gray-400"
              } ${hasAnswered ? "cursor-not-allowed " : ""}`
            )}
            disabled={hasAnswered}
          >
            <div className="w-full text-left">
              <div className="flex items-center">
                <div className="inline mr-4 text-3xl transition-colors">
                  {selected.includes(choice?.uuid as string) ? (
                    <Icon
                      name={
                        choice?.isCorrect || !hasAnswered
                          ? "check_circle"
                          : examItem.showResults
                          ? "cancel"
                          : "check_circle"
                      }
                      className={` animate-fadeIn flex items-center  rounded-full m-0 h-[30px] w-[30px] text-center ${getIconColor(
                        {
                          choiceUuid: choice?.uuid || "",
                          hasAnswered: hasAnswered,
                          isCorrect: choice?.isCorrect || false,
                          selected: (selected as string[]) || "",
                          showResults: examItem.showResults || false,
                        }
                      )}`}
                      style={
                        hasAnswered && examItem.showResults
                          ? {
                              fontFamily: textStyle?.fontFamily,
                            }
                          : textStyle
                      }
                    />
                  ) : (
                    <Icon
                      name="radio_button_unchecked"
                      className="text-black"
                    />
                  )}
                </div>

                <p style={textStyle}>
                  {choice?.longInput || choice?.shortInput || ""}
                </p>
              </div>

              <div className="block w-full">
                {choice?.imageUrl || choice?.mediaUrl ? (
                  <Image
                    src={choice.imageUrl || choice.mediaUrl || ""}
                    className="mx-auto mb-4 max-h-64"
                  ></Image>
                ) : (
                  <></>
                )}
                {choice?.audioUrl ? (
                  <audio controls className="w-full ">
                    <source src={choice.audioUrl} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </button>
        );
      })}
    </div>
  );
}
