import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";
import { ExternalLink } from "../ExternalLink";
import { HTMLAttributeAnchorTarget } from "react";
import { AnchorHTMLAttributes } from "react";

export interface MarkdownProps {
  text: string;
  className?: string;
}

export function Markdown({
  text = "default value",
  className = "h-auto max-w-max",
}: MarkdownProps) {
  const demoText = `  <html>

  <head>
  
  <style>
  .mainSection {
    width:100%;
    height: 100%;
    min-height: 100%;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    background-color: #F7CE64;
  }

  .title {
    color: black;
    font-size: 42px;
    font-weight: 700;
  }

  .subtitle {
    color: black;
    font-size: 24px;
  }

  .mainImage {
    height: 400px;
    width: 400px;
    margin-bottom: 20px;
    border-radius: 20px;
  }

  </style>
  
  </head>
  
  <section class="mainSection ">
  <h1 class="title" >Congratulations lorem lorem lorem </h1>

  <h2 class="subtitle" >Subtext goes here</h2>
  
  <img src="https://images.unsplash.com/photo-1575936123452-b67c3203c357?auto=format&fit=crop&q=80&w=1000&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D" class="mainImage" />
  
  <a href="https://www.google.com/" style="font-size:24px;  background-color:white; padding: 2px 8px; border-radius: 20px; border-width: 2px; border-color: black;">Download now!</a> 
  </section>

  </html>`;

  return (
    <ReactMarkdown
      className={`prose ${className}`}
      rehypePlugins={[rehypeRaw]}
      remarkPlugins={[remarkGfm]}
      remarkRehypeOptions={{
        allowDangerousHtml: false,
      }}
      components={{
        a(props: AnchorHTMLAttributes<{}>) {
          const uri = props.href;
          const style = props.style;
          return (
            <ExternalLink key={uri} uri={props.href || ""} style={style}>
              {props.children}
            </ExternalLink>
          );
        },
      }}
    >
      {text}
    </ReactMarkdown>
  );
}
