import {
  CourseResource,
  ScreenModal,
} from "../../../molecules/components/CastBlocks";
import {
  Icon,
  PillLabel,
  Image,
  Label,
  Markdown,
  Button,
} from "../../../atoms/components";
import { CourseOutline } from "../../../molecules/components";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import { ChooseBatchModal } from "../ChooseBatchModal";
import { gql, useQuery } from "@apollo/client";
import { useErrorHandler } from "react-error-boundary";
import { useCourseCardOutlineQuery } from "../../../generated/graphql";

export interface CourseCardProps {
  courseResource?: CourseResource;
  screenModal?: ScreenModal;
}

export function CourseCard({
  courseResource = {
    image: "https://picsum.photos/200",
    name: "I'm a Super Long Course Resource Name",
    tags: ["Description", "Course Outline"],
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla auctor semper lorem at egestas. *Etiam sollicitudin tellus nec nisi suscipit, eu ultricies nibh aliquam.* Ut non turpis enim. Phasellus aliquet vitae lacus quis malesuada. ***Vivamus ac lectus eleifend, porta enim ut, pulvinar arcu.*** Vivamus sollicitudin sem massa, non elementum justo tempus at. Phasellus vitae massa ut justo pretium finibus id in massa. **Praesent dictum**, sapien ac vestibulum gravida, nisl sem convallis magna, et laoreet orci erat sed nisl. Maecenas risus ex, ultrices sed nibh vitae, maximus interdum sapien. `Vestibulum sodales dignissim hendrerit.` In bibendum magna augue, nec elementum nisl pharetra tincidunt. Maecenas condimentum tortor vel ante tristique, vel accumsan risus lobortis. [Nunc pellentesque viverra odio. Vivamus tincidunt, orci non aliquam rutrum, lectus tellus pharetra justo, sit amet finibus neque leo sed lacus.](https://anvilproject.org/guides/content/creating-links) Curabitur eleifend magna dolor, eget cursus turpis facilisis non.",
    courseOutline: [
      {
        courseBlockTitle: "1.1 Item one",
        courseBlockIcon: "menu_book",
      },
      {
        courseBlockTitle: "1.2 Item two",
        courseBlockIcon: "picture_as_pdf",
      },
      {
        courseBlockTitle: "1.3 Item three",
        courseBlockIcon: "play_arrow",
      },
      {
        courseBlockTitle: "1.4 Item four",
        courseBlockIcon: "html",
      },
      {
        courseBlockTitle: "1.5 Item five",
        courseBlockIcon: "menu_book",
      },
      {
        courseBlockTitle: "1.6 Item six",
        courseBlockIcon: "play_arrow",
      },
    ],
  },
  screenModal,
}: CourseCardProps) {
  const [enrolled, setEnrolled] = useState(false);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const pathArray = window.location.pathname.split("/");
  const [searchParams] = useSearchParams();
  const parentScreenName = searchParams.get("from_screen") || pathArray.pop();
  const [isBatchModalOpen, setIsBatchModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  const { loading, data, error } = useCourseCardOutlineQuery({
    variables: {
      resourceUuid: courseResource.uuid || "",
    },
  });
  useErrorHandler(error);
  return (
    <div className="box-border flex flex-col items-center flex-1 h-full gap-2 p-4">
      {/* {enrolled ? <p>Enrolled</p> : <p>Not Enrolled</p>} */}
      <div className="flex flex-col items-center justify-center w-full">
        <Image
          src={courseResource.image || "https://via.placeholder.com/150"}
          alt="course resource photo"
          width={150}
        />
        <Icon name="school" className="text-xl text-gray-500" />
        <h2 className="mb-3 text-xl font-extrabold text-gray-900 break-all">
          {courseResource.name}
        </h2>
        <div className="flex gap-2 mb-4">
          {courseResource.tags?.map((tag) => (
            <PillLabel status="tag" key={tag}>
              {tag}
            </PillLabel>
          ))}
        </div>
      </div>
      <div className="flex-1 w-full h-full mb-4 overflow-y-scroll">
        {courseResource.description && (
          <>
            <Label className="font-black"> Description </Label>
            <hr className="my-2" />
            <Markdown
              text={courseResource.description}
              className="mb-4 text-gray-500"
            />
          </>
        )}
        <Label className="font-black"> Course Outline </Label>

        <hr className="my-2" />
        <CourseOutline
          resourceUuid={courseResource.uuid as string}
          setEnrolled={setEnrolled}
          setFinishedLoading={setFinishedLoading}
        />
      </div>
      <div className="absolute bottom-0 w-full rounded-t-none">
        {finishedLoading ? (
          enrolled ? (
            <div>
              <ChooseBatchModal
                resource={courseResource || ""}
                courseUuid={
                  (data?.resourceViewScreen?.resource?.content?.__typename ===
                    "ResourceContentCourse" &&
                    data?.resourceViewScreen.resource.content.courseDetails
                      ?.courseUuid) ||
                  ""
                }
                isModalOpen={isBatchModalOpen}
                setIsModalOpen={setIsBatchModalOpen}
              />
              <Button
                className="w-full mt-4"
                onClick={() => {
                  let activeEnrollmentSchedules: number = 1;
                  const firstActiveEnrollmentUuid =
                    (data?.resourceViewScreen?.resource?.content?.__typename ===
                      "ResourceContentCourse" &&
                      data.resourceViewScreen.resource.content.courseDetails?.course?.enrolledSchedules?.find(
                        (schedule) => schedule?.status === "ONGOING"
                      )?.uuid) ||
                    "";

                  if (
                    data?.resourceViewScreen?.resource?.content?.__typename ===
                    "ResourceContentCourse"
                  )
                    activeEnrollmentSchedules =
                      data.resourceViewScreen.resource.content.courseDetails?.course?.enrolledSchedules?.filter(
                        (enrolledSchedule) =>
                          enrolledSchedule?.status === "ONGOING"
                      ).length || 1;

                  if (activeEnrollmentSchedules > 1) setIsBatchModalOpen(true);
                  else {
                    navigate(
                      `/view?course_schedule_uuid=${firstActiveEnrollmentUuid}&resource_uuid=${
                        courseResource.uuid || ""
                      }&from_screen=${parentScreenName}&search_value=${searchParams.get(
                        "search_value"
                      )}`
                    );
                  }
                }}
              >
                Next
              </Button>
            </div>
          ) : (
            <Button
              onClick={() => {
                screenModal?.objectFunction.setBatchModal(true);
                screenModal?.objectFunction.setResourceUuid(
                  courseResource.uuid as string
                );
                window.scrollTo(0, 0);
              }}
              className="w-full mt-4"
            >
              Enroll
            </Button>
          )
        ) : (
          <Button
            onClick={() => {
              screenModal?.objectFunction.setBatchModal(true);
              screenModal?.objectFunction.setResourceUuid(
                courseResource.uuid as string
              );
              window.scrollTo(0, 0);
            }}
            className="w-full mt-4"
            disabled
          >
            Loading
          </Button>
        )}
      </div>
    </div>
  );
}
