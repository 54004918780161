import { useState, useEffect } from "react";
import { ExamResponseInput } from "../../../generated/graphql";
import {
  ActivityProps,
  getDefaultValue,
  QuestionHeader,
  getFeedbackColor,
} from "../../../pages/components/GbfSlide";
import { Image, Icon } from "../../../atoms/components";
import { twMerge } from "tailwind-merge";
import { ColorObj } from "../../../helpers/ColorHelper";

export function MultipleChoiceSlideActivity({
  examItem,
  handleResponse,
  savedResponses,
  rightPageFeedback,
  finalAppearance,
}: ActivityProps) {
  const [selected, setSelected] = useState(
    getDefaultValue({
      examItem,
      savedResponses,
    })
  );
  const [textToDisplay, setTextToDisplay] = useState<string>(
    examItem.choices?.[0]?.longInput || ""
  );
  const [showDropdown, setShowDropdown] = useState(false);

  const hasAnswered = savedResponses.some(
    (response) => response.item === examItem.uuid
  );

  const textStyle = finalAppearance?.finalTextStyle;

  useEffect(() => {
    const response: ExamResponseInput = {
      item: examItem.uuid,
      choices: selected ? [selected as string] : [],
    };

    handleResponse(response);
  }, [selected]);

  return (
    <div className="w-full " key={examItem.uuid}>
      <QuestionHeader text={examItem.text || ""} style={textStyle} />

      {examItem.subtype !== "DD" ? (
        examItem.choices?.map((choice) => {
          return (
            <button
              key={choice?.uuid}
              onClick={() => setSelected(choice?.uuid || "")}
              className={twMerge(` block w-full text-left text-lg text-gray-700 p-4 mb-4 rounded-3xl border-2 border-black transition-all  ${getFeedbackColor(
                {
                  choiceUuid: choice?.uuid || "",
                  hasAnswered: hasAnswered,
                  isCorrect: choice?.isCorrect || false,
                  selected: (selected as string) || "",
                  showResults: examItem.showResults || false,
                }
              )}
              ${
                selected === choice?.uuid
                  ? ""
                  : hasAnswered
                  ? " hover:bg-white"
                  : " hover:bg-gray-300 active:bg-gray-400"
              } ${hasAnswered ? "cursor-not-allowed " : ""}`)}
              style={textStyle}
              disabled={hasAnswered}
            >
              <div className="flex">
                <MultipleChoiceIcon
                  hasAnswered={hasAnswered}
                  isCorrect={choice?.isCorrect || false}
                  showResults={examItem.showResults || false}
                  selected={selected === choice?.uuid}
                />
                {choice?.longInput || choice?.shortInput || ""}
              </div>

              {choice?.imageUrl || choice?.mediaUrl ? (
                <Image
                  src={choice.imageUrl || choice.mediaUrl || ""}
                  className="mx-auto mb-4 max-h-64"
                ></Image>
              ) : (
                <></>
              )}
              {choice?.audioUrl ? (
                <audio controls className="w-full ">
                  <source src={choice.audioUrl} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              ) : (
                <></>
              )}
            </button>
          );
        })
      ) : (
        <button
          className={twMerge(
            `bg-white text-lg text-left text-gray-700 w-full h-fit p-4 mb-4 rounded-3xl border-black border-2 ${
              showDropdown ? "max-h-[9999px]" : "max-h-40 overflow-hidden"
            } transition-all ${getFeedbackColor({
              choiceUuid: hasAnswered ? (selected as string) : "",
              hasAnswered: hasAnswered,
              isCorrect: rightPageFeedback?.isResponseCorrect || false,
              selected: (selected as string) || "",
              showResults: examItem.showResults || false,
            })} ${hasAnswered ? "cursor-not-allowed " : ""}`
          )}
          onClick={() => setShowDropdown((prev) => !prev)}
          disabled={hasAnswered}
        >
          <div className="flex justify-between">
            <span
              className={` border-black h-fit line-clamp-2 flex-1 ${
                showDropdown ? "border-b-2 mb-4" : ""
              }`}
              style={textStyle}
            >
              {textToDisplay}
            </span>
            <Icon name="expand_more" />
          </div>

          {showDropdown ? (
            <div className={`h-fit transition-all duration-300 ease-in `}>
              {examItem.choices?.map((choice, index) => {
                const isLastItem =
                  index === (examItem.choices?.length as number) - 1;
                return (
                  <div
                    key={choice?.uuid}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelected(choice?.uuid || "");
                      setTextToDisplay(
                        choice?.longInput || choice?.shortInput || ""
                      );
                      setShowDropdown(false);
                    }}
                    role="button"
                    className={`hover:bg-gray-300 active:bg-gray-400 p-1 rounded transition-colors ease-in  border-gray-200 ${
                      !isLastItem ? "border-b-2" : " "
                    }`}
                    style={textStyle}
                  >
                    {choice?.longInput || choice?.shortInput || ""}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="transition-transform duration-300 transform -translate-y-2 opacity-0"></div>
          )}
        </button>
      )}
    </div>
  );
}

type MultipleChoiceIconProps = {
  showResults: boolean;
  isCorrect: boolean;
  hasAnswered: boolean;
  selected: boolean;
};

function MultipleChoiceIcon({
  hasAnswered,
  isCorrect,
  showResults,
  selected,
}: MultipleChoiceIconProps) {
  if (showResults && hasAnswered && selected) {
    if (isCorrect) {
      return (
        <Icon
          name="check_circle"
          className="animate-fadeIn flex items-center mr-4 rounded-full m-0 h-[30px] w-[30px] text-center text-3xl text-success-700"
        />
      );
    } else {
      return (
        <Icon
          name="cancel"
          className="animate-fadeIn flex items-center mr-4 rounded-full m-0 h-[30px] w-[30px] text-center text-3xl text-error-700"
        />
      );
    }
  } else if (hasAnswered && showResults)
    return <div className="w-[30px] h-[30px] mr-4"></div>;

  return <></>;
}
